<template>
  <div class="d-flex flex-col gap-4">
    <div v-if="issue">
      <div v-if="issue.issue_has_value === 1">
        <b-input-group
          size="sm"
          :prepend="issue.issue_prefix"
          :append="issue.issue_suffix"
        >
          <b-form-input
            v-model="value.issue_value"
            autocomplete="off"
            size="sm"
            trim
            :placeholder="issue.issue_placeholder"
          ></b-form-input>
        </b-input-group>
      </div>
      <div v-else class="d-flex gap-1">
        <div>{{ issue.issue_prefix }}</div>
        <div>{{ issue.issue_suffix }}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'IssueOption',
  components: {},
  computed: {
    issue() {
      return this.issues.find((item) => item.id === this.value.issue_id);
    },
  },
  props: {
    value: Object,
    issues: Array,
  },
  data() {
    return {};
  },
};
</script>

<style lang="scss" scoped>
</style>
